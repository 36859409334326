.gameList {
    height: 50vh;
    overflow-x: auto;
    display: block;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.gameList li:before {
    content: '';
    display: none;
}
