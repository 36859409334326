.sideBarWrapper{
    display: flex;
    width: 100%;
    height: 100%;
    transition: margin .5s;
    margin: 0 0 0 -100vw;
    position: absolute;
    z-index: 99;
    background-color: white;
}

.sideBarWrapper.isOpen {
    margin-left: 0;
}

.sideBarOpener {
    position: relative;
    padding: 1em;
    height: 1.5em;
    top: 20%;
    left: 3em;
    border-radius: 10px;
    display: block;
    background-color: white;
    cursor: pointer;
}

.isOpen .sideBarOpener {
    left: 0;
}

.mainFrame {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}

.openHint {
    position: absolute;
    left: 6em;
    top: calc(20% + 1em);
}
